<template>
  <div class="container ml-answer-question mb-5">
    <div class="top-answer-header row">
      <div class="col-lg-6">
        <div class="top-question">
          <span @click="$router.back()" class="text-question"><img src="~/mlearn/icons/home/arrow-back.svg" class="icon-question pr-1 pointer" /> Câu hỏi</span>
          <span class="class-info" @click.prevent="redirectDetail">{{ question.class.name }} &#8226; {{ question.category.name }}</span>
        </div>
        <div class="block-data block-1">
          <!--          <div class="title">Câu hỏi</div>-->
          <div class="content scrollbar">
            <div v-if="question.content" class="question-text">
              <vue-mathjax :formula="question.content" :safe="false"></vue-mathjax>
            </div>
            <div class="question-image d-flex" v-if="question.image.length" >
              <el-image :src="question.image[0]" :preview-src-list="question.image" fit="contain"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 top-answer">
        <div class="title-answer">{{canAnswer ? 'Nhập' : 'Sửa'}} câu trả lời:</div>
        <div class="block-data">
          <!--          <div class="title answer">{{canAnswer ? 'Nhập' : 'Sửa'}} câu trả lời:</div>-->
          <div class="content p-0">
            <TinyEditor v-model="contentAnswer" ref="editor" height="auto" placeholder="Nhập câu trả lời." :has-upload-image="true" @handle-upload="handleUpload"/>
            <input ref="fileInput" type="file" hidden accept="image/*" @change="changeFile">
            <div>
              <div class="col-3 d-flex">
                <span v-if="imagePreview" class="preview position-relative">
                  <img :src="imagePreview" class="view" alt="imagePreview" />
                  <img src="~/mlearn/icons/modal/remove-image.svg" class="remove position-absolute pointer" alt="remove" @click="removeImage()" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> Chi tiết <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Trả lời</span></div>-->
    <div class="row math-height">
      <div class="col-lg-6">
      </div>
      <div class="col-lg-6 pt-3 pt-lg-0">
      </div>
    </div>
    <div class="row align-items-center action no-gutters">
      <div class="col-12 text-right">
        <nuxt-link v-if="!canAnswer" :to="{ path: '/cau-hoi/' + questionId}">
          <button class="send-answer cancel-answer">Hủy</button>
        </nuxt-link>
        <button :class="{'disable':loading}" class="send-answer" @click="canAnswer ? sendAnswer() : updateAnswer()">
          {{ canAnswer ? 'Gửi trả lời' : 'Lưu' }}
          <!--                  <img src="~/mlearn/icons/answer/send.svg" />-->
        </button>
      </div>
    </div>
    <!--    <Rank class="pt-3 plg-0 d-block d-lg-none" :home="true" />-->
    <b-modal v-model="showCrop" hide-header hide-footer no-close-on-backdrop no-close-on-esc modal-class="modal-crop" size="lg" centered>
      <VueCropper ref="cropper" :src="fileCropper || imagePreview" :auto-crop-area="1"/>
      <div class="crop-action text-center pointer">
        <img src="~/mlearn/icons/modal/rotate.svg" @click="rotate(90)" />
      </div>
      <div class="row no-gutters note align-items-center">
        <div class="col-sm-12 text-right">
          <button @click="cropImage()">Chọn</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import Resource from '~/common/api/resource'
import TinyEditor from '~/components/mlearn/extra/Editor'
// import Rank from '~/components/mlearn/rank/index.vue'
import { getToken } from '~/common/utils/auth'
const answerResource = new Resource('qa/answer')
const questionResource = new Resource('qa')
export default {
  components: {
    VueCropper,
    TinyEditor
    // Rank
  },
  beforeRouteLeave (to, from, next) {
    if (this.myTimeOut) {
      clearTimeout(this.myTimeOut)
    }
    if (this.myInterval) {
      clearInterval(this.myInterval)
    }
    this.eventEndTyping()
    return next()
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  async asyncData ({ params, req, store, error, redirect }) {
    const { data } = await questionResource.get(params.slug).catch(() => {
      return error({ statusCode: 404, title: 'Câu hỏi không tồn tại', message: 'Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.' })
    })

    if (!data) {
      return error({ statusCode: 404, title: 'Câu hỏi không tồn tại', message: 'Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.' })
    }

    if (store.state.user.uuid === data.created_by) {
      return error({ statusCode: 404, title: 'Lỗi', message: 'Bạn không thể trả lời câu hỏi của chính mình.' })
    }
    let contentAnswer = ''
    let answerId = ''
    let canAnswer = true
    let listImg = []
    let imagePreview = ''
    if (data.answers) {
      for (let x = 0; x < data.answers.length; x++) {
        if (data.answers[x].created_by === store.state.user.uuid) {
          contentAnswer = data.answers[x].content
          answerId = data.answers[x].id
          canAnswer = false
          listImg = data.answers[x].image
          if (listImg.length) {
            imagePreview = listImg[0]
          }
        }
      }
    }
    if (!data.allow_answer && answerId === '') {
      return redirect('/cau-hoi/' + params.slug)
    }
    return {
      question: data,
      tmp_detailQuestion: data,
      contentAnswer,
      answerId,
      canAnswer,
      listImg,
      imagePreview
    }
  },
  data () {
    return {
      remove_img: false,
      loading: false,
      options: {
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          rotateRight: true,
          rotateLeft: true
        }
      },
      showCrop: false,
      fileCropper: '',
      imageUpload: '',
      myTimeOut: null,
      myInterval: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ]),
    questionId () {
      return this.$route.params.slug
    }
  },
  watch: {
    $route (newRoute) {
      if (newRoute.name === 'tra-loi-slug') {
        this.eventStartTyping()
        this.myInterval = setInterval(() => {
          this.eventStartTyping()
        }, 2000)
      }
    }
  },
  mounted () {
    this.eventStartTyping()
    this.myInterval = setInterval(() => {
      this.eventStartTyping()
    }, 2000)
  },
  methods: {
    handleUpload () {
      if (this.user) {
        this.$refs.fileInput.click()
      } else {
        this.$router.push('/user/login')
      }
    },
    eventStartTyping () {
      if (this.answerId) { return }
      this.$socket.emit('TYPING_ANSWER', { user_id: this.user.uuid, user_avatar: this.user.avatar, fullname: this.user.fullName, question_id: this.questionId })
    },
    eventEndTyping () {
      this.$socket.emit('CANCEL_TYPING_ANSWER', { user_id: this.user.uuid, user_avatar: this.user.avatar, fullname: this.user.fullName, question_id: this.questionId })
    },
    // check status question
    checkQuestion () {
      return new Promise((resolve, reject) => {
        questionResource.get(this.questionId)
          .then((res) => {
            this.tmp_detailQuestion = res.data
            resolve(false)
          })
          .catch(() => {
            this.$notify.error({
              message: 'Không thể thực hiện thao tác. Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.',
              showClose: true,
              position: 'top-right'
            })
            resolve(true)
          })
      })
    },
    // check status answer
    checkAnswer () {
      if (!this.tmp_detailQuestion.answers.length) {
        this.$notify.error({
          message: 'Câu trả lời không tồn tại hoặc đã bị báo cáo vi phạm',
          showClose: true,
          position: 'top-right'
        })
        return true
      }
      const check = this.tmp_detailQuestion.answers.find(item => item.id === this.answerId)
      if (!check) {
        this.$notify.error({
          message: 'Câu trả lời không tồn tại hoặc đã bị báo cáo vi phạm',
          showClose: true,
          position: 'top-right'
        })
        return true
      }
      return false
    },
    sendAnswer () {
      if (!this.contentAnswer) {
        this.$notify({
          message: 'Nội dung không được để trống',
          type: 'warning'
        })
        return
      }
      if (this.loading) { return }
      this.loading = true
      let isFormula = 0
      const match = this.contentAnswer.match(/<math/gi)
      if (match) {
        isFormula = 1
      }
      const params = {
        question_id: this.$route.params.slug,
        content: this.contentAnswer,
        image: this.listImg,
        is_formula: isFormula
      }
      answerResource.store(params)
        .then((res) => {
          if (res.status === 200) {
            this.eventEndTyping()
            this.contentAnswer = ''
            this.$notify({
              title: 'Thành công',
              message: 'Gửi trả lời thành công',
              type: 'success'
            })
            this.$router.push({
              path: '/cau-hoi/' + this.$route.params.slug,
              params: {
                success: true
              }
            })
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: err.response.data.message,
            showClose: false,
            position: 'top-right'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateAnswer () {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const notfoundAnswer = await this.checkAnswer()
      if (notfoundAnswer) {
        return
      }
      if (!this.contentAnswer) {
        this.$notify({
          message: 'Nội dung không được để trống',
          type: 'warning'
        })
        return
      }
      if (this.loading) { return }
      this.loading = true
      let isFormula = 0
      const match = this.contentAnswer.match(/<math/gi)
      if (match) {
        isFormula = 1
      }
      const params = {
        content: this.contentAnswer,
        image: this.listImg,
        is_formula: isFormula
      }
      answerResource.update(this.answerId, params)
        .then((response) => {
          if (response.status === 200) {
            this.contentAnswer = ''
            this.$notify({
              title: 'Thành công',
              message: 'Sửa câu trả lời thành công',
              type: 'success'
            })
            this.$router.push({
              path: '/cau-hoi/' + this.$route.params.slug,
              params: {
                success: true
              }
            })
          }
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            message: err.response.data.message,
            showClose: false
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLinkImg (data) {
      this.listImg = data
    },
    redirectDetailQuestion () {
      this.$router.push('/cau-hoi/' + this.question.id)
    },
    changeFile () {
      const files = this.$refs.fileInput.files
      if (files.length === 0) { return }
      const x = 0
      const file = files[x]
      const size = file.size
      if (size === 0) {
        this.$notify({
          title: 'Lỗi',
          message: 'Ảnh lỗi',
          type: 'error'
        })
        return
      }
      const limit = 8
      if (size > limit * 1024 * 1024) {
        this.$notify({
          title: 'Lỗi',
          message: `Dung lượng không vượt quá ${limit}MB`,
          type: 'error'
        })
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        this.fileCropper = e.target.result
        this.showCrop = true
        this.$refs.cropper.replace(e.target.result)
      }
      reader.readAsDataURL(file)
      this.$refs.fileInput.value = ''
    },
    cropImage () {
      const copper = this.$refs.cropper.getCroppedCanvas()
      const image = copper.toDataURL()
      copper.toBlob((blob) => {
        this.showCrop = false
        this.imagePreview = image
        this.imageUpload = blob
        this.uploadImg()
      }, 'image/png')
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    uploadImg () {
      if (!this.imageUpload) { return }
      const token = getToken()
      const formData = new FormData()
      formData.append('file', this.imageUpload)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/qa/image', formData, config)
        .then((response) => {
          if (response.status === 200) {
            this.listImg = response.data.data.map(function (data) {
              return data.fileDownloadUri
            })
          }
        }).catch((err) => {
          this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
          this.listImg = []
        })
    },
    removeImage () {
      this.imagePreview = ''
      this.imageUpload = ''
      this.listImg = []
    }
  }
}
</script>
<style lang="scss" scoped>
  .ml-answer-question{
    .top-answer-header {
      .top-question {
        margin-bottom: 14px;
      }
      .title-answer {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 14px;
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }
    }
    .class-info {
      font-weight: 500;
      font-size: 14px;
      color: #6B6D70;
      padding: 0 0 14px 10px;
      @media (max-width: 1366px) {
      }
      @media (max-width: 576px) {
        font-size: 13px;
      }
    }
    .text-question {
      font-size: 22px;
      font-weight: 500;
      color: rgba(38, 47, 62, 1);
      @media (max-width: 576px) {
        font-size: 18px;
      }
      .icon-question {
        width: 13px;
        height: 18px;
        @media (max-width: 576px) {
          width: 11px;
          height: 16px;
          padding-bottom: 2px;
        }
      }
    }
    .block-1 {
      @media (max-width: 1366px) {
        margin-bottom: 16px;
      }
    }
    .block-data{
      background: var(--white);
      box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.34);
      border: 1px solid #B3B3B387;
      border-radius: 8px;
      .title{
        background: var(--danger);
        border-radius: 8px 8px 0px 0px;
        font-weight: bold;
        color: var(--white);
        padding:15px 24px;
        font-size: 24px;
        @media (max-width:576px) {
          padding:10px 15px;
          font-size: 20px;
        }
        &.answer{
          background: #004390;
        }
      }
      .content{
        border-radius: 8px;
        padding:24px;
        height: 500px;
        @media (max-width:576px) {
          padding:16px;
          height: 250px;
        }
        .question-image{
          background: #E2EBF6;
          margin-top: 20px;
          max-height: 300px;
          @media (max-width:576px) {
            margin-top: 15px;
            max-height: 150px;
          }
          .el-image{
            max-height: 300px;
            @media (max-width:576px) {
              max-height: 150px;
            }
          }
        }
      }
    }
    .action{
      //border-top: 1px solid #F0F2F5;
      padding:0 24px;
      height: 100px;
      @media (max-width:576px) {
        padding:0 16px;
        height: 45px;
      }
      .preview{
        .view{
          --avatar:90px;
          width: var(--avatar);
          height: var(--avatar);
          object-fit: cover;
          @media (max-width:992px) {
            --avatar:80px;
          }
          @media (max-width:576px) {
            --avatar:45px;
          }
        }
        .remove{
          top: 5px;
          right: 5px;
          @media (max-width:576px) {
            top: 0px;
            right: 0px;
          }
        }
      }
      .send-answer{
        background: rgba(0, 97, 175, 1);
        border-radius: 22px;
        font-weight: 500;
        color: var(--white);
        font-size: 16px;
        padding:10px 30px;
        @media (max-width:576px) {
          font-size: 14px;
          padding:7px 20px;
        }
        &.disabled, &.cancel-answer{
          background: #868789;
        }
      }
    }
  }
  .modal-crop{
    .modal-body{
      padding:1rem !important;
      @media (max-width:576px) {}
      .crop-action{
        padding:10px 0;
      }
      .note{
        button{
          background: #004390;
          border-radius: 22px;
          color:var(--white);
          padding:10px 30px;
          @media (max-width:576px) {
            padding:7px 30px;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .ml-answer-question{
    .tox-tinymce{
      @media (max-width:576px) {
        height: 200px !important;
      }
      @media (min-width:576px) and (max-width:992px) {
        height: 410px !important;
      }
      @media (min-width:992px) {
        height: 400px !important;
      }
      .tox-editor-container{
        .tox-toolbar__primary{
          border-top: 1px solid #F0F2F5 !important;
        }
      }
    }
  }
</style>
